export default {

    required() {
	return function(v) {
	    return !!v || 'Champ obligatoire';
	}
    },

    minLength(min) {
	return function(v) {
	    return (!v || v.length<min) ? 'Doit avoir au moins '+min+' caractères' : true;
	}
    },
    
    maxLength(max) {
	return function(v) {
	    return (v && v.length>max) ? 'Ne doit pas avoir plus de '+max+' caractères' : true;
	}
    },

    email() {
	return function(v) {
	    if (!v) return true; // ne rien renseigner est valide, si l'email est obligatoire ajouter le rule required()
	    var mailformat = /[a-zA-Z0-9._-]+@[a-zA-Z0-9]+.[a-zA-Z]{2,}/;
	    return mailformat.test(v) || "N'est pas une adresse mail valide";
	}
    },

    positive() {
	return function(v) {
	    return v>=0 || 'Doit être positif';
	}
    }
}
