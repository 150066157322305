import Vue from 'vue'
import App from '@/App.vue'
import Vuetify from '@/plugins/vuetify';

import Prompt from '@/plugins/prompt';
Vue.use(Prompt, {vuetify: Vuetify});

import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css"; // Import the CSS or use your own!
let optionsToast = {timeout: 3000, closeOnClick: true, position: POSITION.TOP_LEFT}; // You can set your default options here
Vue.use(Toast, optionsToast);

Vue.config.productionTip = false

new Vue({
  vuetify: Vuetify,
    render: h => h(App)
}).$mount('#app')
