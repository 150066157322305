<template>
<v-app>
  <v-main>

    <v-container fill-height fluid class="background">      

      <v-layout align-center justify-center>
	<v-flex>
	  
	  <div class="text-center">
	    <v-progress-circular v-show="showLoading" :size="70" :width="7" color="purple" indeterminate></v-progress-circular>
	  </div>
	  
	  <Login />
	</v-flex>
      </v-layout>

    </v-container>
  </v-main>
</v-app>
</template>

<script>

import swal from 'sweetalert';
import ApiResponse from "@/modules/api-response";

import Login from '@/components/Login';
import LoginService from "@/services/LoginService";

export default {
    name: 'App',
    components: {Login},

    data() {
	return {
	    showLoading: true, // Montrer l'icone de chargement
	}
    },

    mounted() {
	var s = this;
	LoginService.isLogged().then((response) => {
	    this.showLoading = false;
	    
	    // On a reçu la réponse du serveur
	    let apiResponse = new ApiResponse(response);
	    if (!apiResponse.isSuccess()) {
		swal("Authentification", apiResponse.error());
		return;
	    }
	    
	    let isLogged = apiResponse.datas().logged;
	    if (isLogged) window.location.href = '/admin';
	    
	}).catch((e) => {
	    this.showLoading = false;
	    swal("Authentification", e);
	});
    }
}

</script>

<style>

* { 
    text-transform: none !important; 
}

HTML, .v-main {
    background-color: #EEE;
}

.container {
  padding: 0;
}

.background {
    background: url('./assets/background.jpg');
    background-size: cover;
    height: 100vh;
}

</style>
