<template>

<v-container fill-height fluid>
  <v-row justify="center">
    <!-- 12 colonnes par défaut (donc xs), à partir de 600px => 4 colonnes -->
    <v-col cols="12" sm="4">
    <v-card>
      <v-toolbar color="blue">
	<v-spacer /> <!-- Pour centrer le titre -->
	<v-toolbar-title class="white--text" justify="center">
	  {{title}}
	</v-toolbar-title>
	<v-spacer />
      </v-toolbar>

      <v-form ref="form">
      <v-card-text>
	<v-text-field
	   autofocus
	   prepend-icon="fa-user"
	   label="Identifiant"
	   v-model="login"
	   :rules="loginRules"
	   @keyup.enter="submit"
	   ></v-text-field>
	
	<v-text-field	   
	   prepend-icon="fa-lock"
	   label="Mot de passe"
	   v-model="password"
	   type="password"
	   :rules="passwordRules"
	   @keyup.enter="submit"
	   ></v-text-field>
      </v-card-text>

      <v-card-text align="right" class="ma-0 pa-0 pr-3 mb-5">
	<a @click="passwordForgottenClicked">Mot de passe oublié ?</a>
      </v-card-text>

      <v-card-actions>
	<v-row justify="center">
	  <v-btn @click="submit" color="primary" elevation="10">Se connecter</v-btn>
	</v-row>
      </v-card-actions>
      </v-form>

      <br/><br/>

    </v-card>
</v-col>
</v-row>
</v-container>
</template>

<script>
    
import ApiResponse from "@/modules/api-response";
import validationRules from '@/modules/utils/validationRules';

import LoginService from "@/services/LoginService";

export default {
    name: 'Login',
    data() {
	return {
	    title: "Karaté Club d'Ambert",

	    login: '',
	    password: '',
	    
	    loginRules: [validationRules.required()],
	    passwordRules: [validationRules.required()],
	}
    },

    mounted() {
	document.title = this.title + ' - Connexion';
    },

    methods: {
	passwordForgottenClicked() {
	    let opts = {
		type: 'text',
		text: 'Renseignez votre adresse mail pour recevoir vos identifiants',
		icon: 'fa-envelope',
		label: 'Adresse mail',
		rules: [validationRules.required(), validationRules.email()]
	    };
	    var s = this;
	    this.$prompt(opts).then(function(res) {
		if (!res) return;
		
		LoginService.sendIdentifiants({email: res}).then((response) => {
		    let apiResponse = new ApiResponse(response);
		    if (!apiResponse.isSuccess()) {
			s.$toast.error(apiResponse.error());
			return;
		    }
		    
		    s.$toast.success('Vos identifiants ont été envoyés');
		    
		}).catch((e) => {
		    s.$toast.error(e);
		});
		
	    });
	},

	submit() {
	    if (!this.$refs.form.validate()) return;
	    
	    LoginService.login({login: this.login, password: this.password}).then((response) => {
		
		// On a reçu la réponse du serveur
		let apiResponse = new ApiResponse(response);
		if (!apiResponse.isSuccess()) {
		    this.$toast.error(apiResponse.error());
		    return;
		}
		
		window.location.href = '/admin';
		
	    }).catch((e) => {
		this.$toast.error(e);
	    });
	},
	
	logout() {
	    LoginService.logout().then((response) => {
		window.location.href = '/login';
		
	    }).catch((e) => {
		this.$toast.error(e);
		this.reset();
	    });
	},
	
	reset() {
	    this.login = '';
	    this.password = '';

	    this.$refs.form.reset();
	}
    }
}
</script>
