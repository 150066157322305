<template>

<v-dialog value="true" :width="width" persistent>
  <v-card class="pa-2">

    <v-card-text>
      <strong><span v-html="text"></span></strong>
    </v-card-text>

    <v-divider></v-divider>

    <v-form ref="form">
      <v-card-text>

	<v-text-field v-if="type=='text'" autofocus :prepend-icon="icon" :label="label" :hint="hint" :rules="rules" v-model="value" @input="onChange" @keyup.enter="submit" />
	<v-textarea v-if="type=='textarea'" :prepend-icon="icon" :label="label" :hint="hint" v-model="value" outlined />
	<v-text-field v-if="type=='number'" type="number" autofocus :prepend-icon="icon" :label="label" :hint="hint" :rules="rules" v-model="value" @input="onChange" @keyup.enter="submit" />
	<v-select v-if="type=='select'" :prepend-icon="icon" :label="label" :hint="hint" :items="values" v-model="value" item-value="value" item-text="name" />
	<select-timezone v-if="type=='timezone'" :prepend-icon="icon" :label="label" :hint="hint" v-model="value" />

      </v-card-text>
    </v-form>

    <v-divider></v-divider>
    
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn color="lightgrey" raised @click="choose(false)">
        {{btnCancel}}
      </v-btn>

      <v-btn color="primary" raised @click="choose(true)">
        {{btnOk}}
      </v-btn>
    </v-card-actions>
  </v-card>  
</v-dialog>

</template>

<script>
    
export default {
    name: 'Prompt',
    props: ['type', 'width', 'text', 'icon', 'label', 'defaultValue', 'values', 'hint', 'rules', 'btnCancel', 'btnOk', 'min', 'max'],
    data: () => ({
	value: false
    }),
    mounted() {
	this.value = this.defaultValue;
	if (this.type=='number') {
	    if (this.min!==false && this.value<this.min) this.value = this.min;
	    if (this.max!==false && this.value>this.max) this.value = this.max;
	}
    },
    methods: {
	choose(value) {
	    if (!value) this.value = false; // On a cliqué sur Cancel, value passe à false
	    else {
		if (!this.$refs.form.validate()) return;
		// this.value est la valeur du champ
	    }

	    this.$destroy();
	},
	onChange() {
	    if (this.type=='number') {
		var s = this;
		if (this.min!==false && this.value<this.min) setTimeout(function() { s.value = s.min; }, 10);
		if (this.max!==false && this.value>this.max) setTimeout(function() { s.value = s.max; }, 10);
	    }
	}
    }
};

</script>
