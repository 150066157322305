import Prompt from './Prompt.vue'

/**
   options: {
     vuetify: Vuetify obligatoire
   }

   this.$prompt({
     type: 'text',
     text: 'Renseignez le champ'
     icon: '', par exemple 'fa-user'
     label: 'label',
     defaultValue: '',
     hint: '',
     rules: [],
     btnCancel: 'Annuler',
     btnOk: 'OK',
     width: 450
   })

   type: text | textarea
   type: select
     values: [
       {id: , value:},
       ...
     ]
   type: number
     min:
     max:
     

*/
export default {
    install(Vue, opts = {}) {
	if (this.installed) return;
	this.installed = true;

	let vuetify = opts.vuetify;
	delete opts.vuetify;

	let options = Object.assign({}, {
	    type: 'text',
	    text: 'Merci de renseigner le champ ci-dessous',
	    icon: '',
	    label: 'Nom',
	    defaultValue: '',
	    min: false,
	    max: false,
	    hint: '',
	    rules: [],
	    btnCancel: 'Annuler',
	    btnOk: 'OK',
	    width: 450
	    
	}, opts);

	const Ctor = Vue.extend(Object.assign({vuetify: vuetify}, Prompt));

	const prompt = params => {
	    const container = document.querySelector('[data-app=true]') || document.body;
	    return new Promise(resolve => {
		let props = Object.assign({}, options, params);
		const cmp = new Ctor(Object.assign({}, {
		    propsData: props,
		    destroyed: () => {
			container.removeChild(cmp.$el);
			resolve(cmp.value);
		    }
		}));
		container.appendChild(cmp.$mount().$el);
	    });
	};

	Vue.prototype['$prompt'] = prompt;
	Vue['$prompt'] = prompt;
    }
};
