import axios from "axios";
//import store from "./store";

const instance = axios.create({
    //baseURL: 'http://karate.elforia-design.fr/apis',
    baseURL: 'https://karateclubambert.elforia-design.fr/apis',
    method: 'POST',
    headers: {
	'Content-type': 'application/json'
    }
});

instance.interceptors.request.use((config) => {
    console.info('request: ', config.baseURL+config.url);
    return config;
    
}, (error) => {
    // Requête incorrecte
    return Promise.reject(error);
});

/**
   response si le serveur a répondu:

     config: Object { url: "/Login/login", method: "POST", baseURL: "http://karate.elforia-design.fr/apis"...}
     data: Object { result: 0, type: "ParametersException", error: 1...}
     headers: Object { "cache-control": "no-store, no-cache, must-revalidate, post-check=0, pre-check=0", "content-type": "text/html; charset=UTF-8", expires: "Thu, 19 Nov 1981 08:52:00 GMT"...}
     request: XMLHttpRequest { readyState: 4, timeout: 0, withCredentials: false...}
     status: 200
     statusText: "OK"
*/
instance.interceptors.response.use((response) => {
    if (response.status!=200) return Promise.reject("Erreur du serveur: "+response.statusText+" [code "+response.status+"]");

    console.log(response.data);
    //if (response.data.datas && response.data.datas.token) store.commit('logged/setToken', response.data.datas.token);

    return response;
    
}, (error) => {
    console.log(error);

    var msg = '';
    
    if (error.response) { // The request was made and the server responded with a status code that falls out of the range of 2xx
	msg = "Erreur du serveur: "+error.response.statusText+" [code "+error.response.status+"]";
	
    } else if (error.request) { // The request was made but no response was received
	msg = "Le serveur a retourné un document vide";
	
    } else {
	// Something happened in setting up the request that triggered an Error
	msg = "Erreur du serveur";
    }
    
    return Promise.reject(msg);
});

export default instance;
