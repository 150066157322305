/**
   MyDate.ellapsed(moment)
   MyDate.secondsToStr(seconds)
   MyDate.momentToString(moment)
*/
import moment from 'moment';
export default class MyDate { }

MyDate.tm_server = 0;
MyDate.tm_client = 0;
MyDate.locale = 'fr'; // A configurer

MyDate._momentSyncNow = function() {
    return moment().add(MyDate.tm_server-MyDate.tm_client, 'seconds');
};

MyDate.isSync = function() { return MyDate.tm_server!=0; }

MyDate.sync = function(tm_server) {
    MyDate.tm_server = tm_server;
    
    let d = new Date();
    MyDate.tm_client = Math.round(d.getTime()/1000);

    console.log('Date synchronisée avec le serveur');
};

/**
   Transformer une date YYYY-MM-DD en date locale (ex. dd/mm/YYYY pour fr)
*/
MyDate.dateToLocale = function(date) {
    let parts = date.split('-');
    
    switch (MyDate.locale) {
    case 'fr': return parts[2]+'/'+parts[1]+'/'+parts[0];
    }
    
    return date;
};

MyDate.ellapsed = function(moment, format) {
    var sec = 0;

    if (MyDate.tm_server==0) {
	alert("MyDate.tm_server n'est pas renseigné");
	
    } else {
	var d = new Date();
	sec = Math.round(d.getTime()/1000) - moment.unix() + MyDate.tm_server - MyDate.tm_client;
    }
    
    if (format==undefined) return sec;
    else if (format=='s') return MyDate.secondsToStr(sec);
};

MyDate.secondsToStr = function(secondes) {
    if (secondes<=0) return "0s";
    
    var jours = Math.floor(secondes/3600/24);
    secondes -= jours*3600*24;
    
    var heures = Math.floor(secondes/3600);
    secondes -= heures*3600;
    
    var minutes = Math.floor(secondes/60);
    secondes -= minutes*60;
    
    var str = "";
    if (jours==1) str += jours+" jour ";
    else if (jours>1) str += jours+" jours ";
    if (str!="" || heures>0) str += heures+"h";
    if (str!="" || minutes>0) str += minutes+"mn";
    if (str!="" || secondes>0) str += secondes+"s";

    return str;
};


MyDate.format = function(moment, opts) {
    return MyDate.momentToString(moment, opts);
};

MyDate.isoToString = function(iso, opts) {
    let m = moment.parseZone(iso);
    return MyDate.momentToString(m, opts);
};

/**
   date (moment): est une date qui peut très bien avoir un fuseau horaire différent de celui du client
   options:
     time: true [default]
*/
MyDate.momentToString = function(moment, opts) {
    var defaultOptions = {
	time: true,
	rel: true // Affiche Aujourd'hui ou Hier
    };
    let options = Object.assign({}, defaultOptions, opts);

    if (!MyDate.tm_server) return 'Heures non synchronisées';

    var now = MyDate._momentSyncNow();
    var then = moment.clone();
    
    var offset = then.utcOffset(); // en minutes, Ex. +11:00 => 660 minutes
    var zNow = now.clone().utcOffset(offset); // Exprime now dans le fuseau horaire de then
    
    //debug('now: '+now.format()+' => zNow :'+zNow.format());
    //debug('then :'+then.format());
    
    then.startOf('day');
    zNow.startOf('day');
    
    var res = '';
    
    var diff = zNow.diff(then, 'days');
    if (options.rel) {
	if (diff==0) res = "Aujourd'hui";
	else if (diff==1) res = "Hier";
	else res = moment.format('DD/MM/YYYY');
	
    } else {
	res = moment.format('DD/MM/YYYY');
    }
    
    if (options.time) res += moment.format(' HH:mm:ss');
    return res;
};

MyDate.momentToIso = function(moment) {
    return moment.format();
};

MyDate.months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
MyDate.days = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];
