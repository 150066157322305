/**
   A créer sur la réponse d'une requête axios

   isSuccess()
   alors on a accès à la réponse du serveur qui est data()

   sinon:
     errorType():
     PDOException
     ParametersException alors params() contient les champs qui ont généré une erreur
     AuthException
     RouterException
     ApiException
     generic

*/

import MyDate from '@/modules/utils/MyDate';

export default class ApiResponse {

    constructor(response) {
	if (!MyDate.isSync() && response.data.tm) MyDate.sync(response.data.tm);

	this.response = response;
    }

    datas() {
	return this.response.data.datas;
    }

    deps() {
	return this.response.data.deps;
    }
    
    isSuccess() {
	return this.response.data && this.response.data.result;
    }

    errorType() {
	return this.response.data.type;
    }

    error() {
	if (this.response.data.type=='ParametersException') {
	    if (this.response.data.params.length==1) return "Un champ du formulaire est incorrect";
	    return this.response.data.params.length+" champs sont incorrects";
	}
	return this.response.data.error;
    }

    isParametersException() {
	return this.response.data.type=='ParametersException';
    }

    /**
       [
         {param: 'nom', code: 'LENGTH_TOO_SMALL', msg: "Ce champ doit avoir au moins 2 caractères", value: 'a'}
	 ...
       ]
     */
    params() {
	return this.response.data.params;
    }

    /**
       schema: {
         nom: {label: 'Nom'},
	 rue: {label: 'Rue'}
	 ...
       }
    */
    formValidationError(schema) {
	let lines = [];
	for (var i=0;i<this.response.data.params.length;i++) {
	    let param = this.response.data.params[i];

	    if (!schema[param.param] || !schema[param.param].label) lines.push(param.msg);
	    else lines.push('Champ '+schema[param.param].label+': '+param.msg);
	}

	return lines.join("\n");
    }

}
