import apis from "@/../../_modules/http-apis";

class LoginService {
    login(params) {
	return apis.post("/login/Login", params);
    }

    isLogged() {
	return apis.post("/login/isLogged");
    }

    logged() {
	return apis.post("/login/Logged");
    }

    logout() {
	return apis.post("/login/Logout");
    }

    sendIdentifiants(params) {
	return apis.post("/login/SendIdentifiants", params);
    }
}

export default new LoginService();
